var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id:
          _vm.modalType === "reschedule"
            ? "rescheduleReminder"
            : _vm.modalType === "reopen"
            ? "reopenReminder"
            : "setReminder",
        "hide-header": "",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        size: "xl",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "p-4" },
        [
          _c("b-col", { attrs: { cols: "12", md: "4" } }, [
            _c("h1", { staticClass: "heading tw-mb-5 md:tw-mb-0" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.modalType === "reschedule"
                      ? "Reschedule Reminder"
                      : _vm.modalType === "reopen"
                      ? "Re-open Reminder"
                      : "Set Reminder"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center mb-6" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "mb-0 mr-3",
                      staticStyle: { "font-size": "16px" },
                    },
                    [_vm._v("for")]
                  ),
                  _vm.selectedReminder
                    ? _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: {
                            "font-size": "22px",
                            "font-weight": "500",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedReminder.subjectid
                                  ? _vm.selectedReminder.name
                                  : "Me"
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c("v-select", {
                        staticStyle: { width: "100%", "font-size": "16px" },
                        attrs: {
                          id: "user",
                          "calculate-position": _vm.withPopper,
                          label: "text",
                          options: _vm.userOptions,
                          reduce: (option) => option.value,
                        },
                        on: { search: _vm.onSearch, input: _vm.onInput },
                        scopedSlots: _vm._u([
                          {
                            key: "open-indicator",
                            fn: function ({ attributes }) {
                              return [
                                _c(
                                  "b-icon",
                                  _vm._b(
                                    {
                                      attrs: {
                                        icon: "chevron-down",
                                        variant: "primary",
                                      },
                                    },
                                    "b-icon",
                                    attributes,
                                    false
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedUser,
                          callback: function ($$v) {
                            _vm.selectedUser = $$v
                          },
                          expression: "selectedUser",
                        },
                      }),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            show: _vm.alert.show,
                            variant: _vm.alert.type,
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "mb-4", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { attrs: { role: "group" } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "text-muted mb-0",
                            attrs: { for: "date" },
                          },
                          [_c("small", [_vm._v("Date")])]
                        ),
                        _c("b-form-datepicker", {
                          attrs: {
                            id: "date",
                            locale: "en",
                            "date-disabled-fn": _vm.dateDisabled,
                          },
                          model: {
                            value: _vm.reminderdate,
                            callback: function ($$v) {
                              _vm.reminderdate = $$v
                            },
                            expression: "reminderdate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { staticClass: "mb-4", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { attrs: { role: "group" } },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "text-muted mb-0",
                            attrs: { for: "time" },
                          },
                          [_c("small", [_vm._v("Time")])]
                        ),
                        _c("b-form-timepicker", {
                          attrs: {
                            id: "time",
                            locale: "en",
                            "minutes-step": "15",
                          },
                          model: {
                            value: _vm.remindertime,
                            callback: function ($$v) {
                              _vm.remindertime = $$v
                            },
                            expression: "remindertime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "mb-4 pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-muted mb-0",
                              attrs: { for: "reminder" },
                            },
                            [_c("small", [_vm._v("Reminder")])]
                          ),
                          _c("b-form-textarea", {
                            staticClass: "pl-0",
                            staticStyle: { overflow: "auto" },
                            attrs: {
                              id: "reminder",
                              rows: "3",
                              "max-rows": "6",
                            },
                            model: {
                              value: _vm.reminder,
                              callback: function ($$v) {
                                _vm.reminder = $$v
                              },
                              expression: "reminder",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-h": "end" } },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "pr-0",
                              attrs: { cols: "6", md: "4" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    pill: "",
                                    block: "",
                                    variant: "outline-primary",
                                  },
                                  on: { click: _vm.onCancel },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pr-0",
                              attrs: { cols: "6", md: "4" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    pill: "",
                                    block: "",
                                    variant: "primary",
                                    disabled: _vm.isProcessing,
                                  },
                                  on: { click: _vm.saveReminder },
                                },
                                [
                                  _vm.isProcessing
                                    ? _c("b-spinner", { attrs: { small: "" } })
                                    : _c("span", [_vm._v("Save")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
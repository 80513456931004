<template>
  <b-modal
    :id="
      modalType === 'reschedule'
        ? 'rescheduleReminder'
        : modalType === 'reopen'
        ? 'reopenReminder'
        : 'setReminder'
    "
    hide-header
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    size="xl"
  >
    <b-row class="p-4">
      <b-col cols="12" md="4">
        <h1 class="heading tw-mb-5 md:tw-mb-0">
          {{
            modalType === "reschedule"
              ? "Reschedule Reminder"
              : modalType === "reopen"
              ? "Re-open Reminder"
              : "Set Reminder"
          }}
        </h1>
      </b-col>
      <b-col cols="12" md="6">
        <div class="d-flex align-items-center mb-6">
          <p class="mb-0 mr-3" style="font-size: 16px">for</p>
          <p
            v-if="selectedReminder"
            class="mb-0"
            style="font-size: 22px; font-weight: 500"
          >
            {{ selectedReminder.subjectid ? selectedReminder.name : "Me" }}
          </p>
          <v-select
            v-else
            style="width: 100%; font-size: 16px"
            id="user"
            :calculate-position="withPopper"
            v-model="selectedUser"
            label="text"
            :options="userOptions"
            :reduce="(option) => option.value"
            @search="onSearch"
            @input="onInput"
          >
            <template #open-indicator="{ attributes }">
              <b-icon
                v-bind="attributes"
                icon="chevron-down"
                variant="primary"
              ></b-icon>
            </template>
          </v-select>
        </div>
        <b-row>
          <b-col cols="12">
            <b-alert :show="alert.show" :variant="alert.type">
              {{ alert.message }}
            </b-alert>
          </b-col>
          <b-col cols="12" class="mb-4">
            <div role="group">
              <label for="date" class="text-muted mb-0">
                <small>Date</small>
              </label>
              <b-form-datepicker
                id="date"
                locale="en"
                :date-disabled-fn="dateDisabled"
                v-model="reminderdate"
              ></b-form-datepicker>
            </div>
          </b-col>
          <b-col cols="12" class="mb-4">
            <div role="group">
              <label for="time" class="text-muted mb-0">
                <small>Time</small>
              </label>
              <b-form-timepicker
                id="time"
                locale="en"
                minutes-step="15"
                v-model="remindertime"
              ></b-form-timepicker>
            </div>
          </b-col>
          <b-col cols="12" class="mb-4 pr-0">
            <div role="group">
              <label for="reminder" class="text-muted mb-0">
                <small>Reminder</small>
              </label>
              <b-form-textarea
                id="reminder"
                class="pl-0"
                rows="3"
                max-rows="6"
                style="overflow: auto"
                v-model="reminder"
              ></b-form-textarea>
            </div>
          </b-col>
          <b-col cols="12" class="pr-0">
            <b-row align-h="end">
              <b-col cols="6" md="4" class="pr-0">
                <b-button
                  @click="onCancel"
                  pill
                  block
                  variant="outline-primary"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col cols="6" md="4" class="pr-0">
                <b-button
                  pill
                  block
                  variant="primary"
                  @click="saveReminder"
                  :disabled="isProcessing"
                >
                  <b-spinner v-if="isProcessing" small></b-spinner>
                  <span v-else>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import {
  format,
  parse,
  sub,
  roundToNearestMinutes,
  getUnixTime,
  fromUnixTime,
} from "date-fns";
import _ from "lodash";
import popper from "@/assets/js/popper.js";
export default {
  props: {
    patient: {
      type: Object,
      default: () => ({}),
    },
    modalType: {
      type: String,
      default: "set",
    },
    selectedReminder: {
      type: [Object, null],
      default: null,
    },
  },
  mixins: [popper],
  data() {
    return {
      alert: {
        show: false,
        message: "",
      },
      selectedUser: "",
      nextPage: null,
      userOptions: [],

      reminderdate: "",
      remindertime: "",
      reminder: "",

      searchCounter: 0,
      isProcessing: false,
    };
  },
  watch: {
    patient() {
      this.setInitialUserOptions();
    },
    user() {
      this.setInitialUserOptions();
    },
    selectedReminder(reminder) {
      if (reminder) {
        if (this.modalType === "reopen") {
          this.reminderdate = "";
        } else {
          this.reminderdate = format(
            fromUnixTime(reminder.reminderdatetimeutc),
            "yyyy-MM-dd"
          );
          this.remindertime = format(
            fromUnixTime(reminder.reminderdatetimeutc),
            "HH:mm:ss"
          );
        }
        this.title = reminder.title;
        this.reminder = reminder.reminder;
      }
    },
  },
  created() {
    this.refreshForm();
    this.setInitialUserOptions();
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    dateDisabled(ymd, date) {
      return sub(new Date(), { days: 1 }) > date;
    },
    async onSearch(search, loading) {
      // Call search function once
      this.searchCounter += 1;
      if (this.searchCounter === 2) {
        this.searchCounter = 0;
        return;
      }

      if (search) {
        loading(true);
        this.fetchUserOptions(search, loading);
      }
    },
    fetchUserOptions: _.debounce(async function (search, loading) {
      let patients = await this.$store.dispatch(
        "patients/searchPatient",
        search
      );
      const parsedPatientList = patients.map((patient) => ({
        text: `${patient.firstname} ${patient.lastname}`,
        value: patient.id,
      }));
      this.userOptions = [
        {
          text: "Me",
          value: this.$store.state.user.currentUser.id,
        },
        ...parsedPatientList,
      ];
      loading(false);
    }, 350),
    onInput(val) {
      if (!val) {
        this.setInitialUserOptions();
        this.selectedUser = "";
      }
    },
    setInitialUserOptions() {
      this.userOptions = [];
      this.userOptions.push({
        text: "Me",
        value: this.$store.state.user.currentUser.id,
      });

      const isPatientPropsEmpty = _.isEmpty(this.patient);
      if (!isPatientPropsEmpty) {
        this.userOptions.push({
          text: `${this.patient.firstName} ${this.patient.lastName}`,
          value: this.patient.id,
        });
      }

      this.selectedUser = isPatientPropsEmpty
        ? this.$store.state.user.currentUser.id
        : this.patient.id;
    },
    async saveReminder() {
      if (
        !this.selectedUser ||
        !this.reminder ||
        !this.reminderdate ||
        !this.remindertime
      ) {
        return this.showAlert("Please fill in all fields.");
      }

      if (
        parse(
          `${this.reminderdate} ${this.remindertime}`,
          "yyyy-MM-dd HH:mm:ss",
          new Date()
        ) < new Date()
      ) {
        return this.showAlert("Please select a future date and time.");
      }

      let payload = {
        reminderdatetimeutc: getUnixTime(
          parse(
            `${this.reminderdate} ${this.remindertime}`,
            "yyyy-MM-dd HH:mm:ss",
            new Date()
          )
        ),
        reminder: this.reminder,
      };

      if (this.modalType !== "reschedule" && this.modalType !== "reopen") {
        if (this.selectedUser === this.$store.state.user.currentUser.id) {
          payload.subjecttype = "DOCTOR";
        } else {
          payload.subjecttype = "PATIENT";
          payload.subjectid = this.selectedUser;
        }
      }

      try {
        this.isProcessing = true;
        if (this.modalType === "reschedule") {
          await this.$store.dispatch("reminder/rescheduleReminder", {
            reminderid: this.selectedReminder.id,
            routename: this.$route.name,
            patientid: localStorage.getItem("patientId"),
            payload,
          });
          this.refreshForm();
          this.$bvModal.hide("rescheduleReminder");
        } else if (this.modalType === "reopen") {
          await this.$store.dispatch("reminder/reopenReminder", {
            reminderid: this.selectedReminder.id,
            routename: this.$route.name,
            patientid: localStorage.getItem("patientId"),
            payload,
          });
          this.refreshForm();
          this.$bvModal.hide("reopenReminder");
        } else {
          await this.$store.dispatch("reminder/addReminder", {
            routename: this.$route.name,
            patientid: localStorage.getItem("patientId"),
            payload,
          });
          this.refreshForm();
          this.setInitialUserOptions();
          this.$bvModal.hide("setReminder");
        }
        this.isProcessing = false;
      } catch (e) {
        this.isProcessing = false;
        this.alert = {
          show: false,
          message: "",
        };
      }
    },
    refreshForm() {
      this.reminderdate = format(new Date(), "yyyy-MM-dd");
      this.remindertime = format(
        roundToNearestMinutes(new Date(), { nearestTo: 15 }),
        "HH:mm:ss"
      );
      this.title = "";
      this.reminder = "";

      this.alert = {
        show: false,
        message: "",
      };
    },
    showAlert(message, type = null) {
      this.alert = {
        show: true,
        message: message,
        type: type || "warning",
      };
    },
    onCancel() {
      this.$emit("modal-close");
      if (this.modalType === "reschedule") {
        this.$bvModal.hide("rescheduleReminder");
      } else if (this.modalType === "reopen") {
        this.$bvModal.hide("reopenReminder");
      } else {
        this.refreshForm();
        this.setInitialUserOptions();
        this.$bvModal.hide("setReminder");
      }
    },
  },
};
</script>

<style scoped>
/deep/#date__outer_,
/deep/#time__outer_ {
  display: flex;
}
/deep/#date.btn,
/deep/#time.btn {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0.75rem;
}
/deep/#date__value_.form-control,
/deep/#time__value_.form-control {
  padding: 0;
  line-height: 32px;
}

/deep/#time__outer_ #time__dialog_ .b-form-spinbutton {
  padding-right: 12px;
}
</style>
